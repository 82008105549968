import React, { useState } from "react";
import classes from "./homescreen.module.css";
import faceLogo from "../Images/faceLogo.png";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const HomeScreen = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <Navbar/>
      <div className={classes.content}>
        <div className={classes.left}>
          <div className={classes.heading}>
            Apollo Health Check: Measure Your Vitals
          </div>
          <div className={classes.description}>
            Apollo Health Check is an AI solution developed by Aivot AI that
            measures your body vitals, including heart rates, spo2, SBP, DBP,
            Blood Sugar etc through facial video analysis.
          </div>
          <div className={classes.btnSec}> 
            <button className={classes.btn } onClick={()=>{localStorage.setItem('page','apollomember');navigate('/login')}}>Apollo Member</button>
            <button className={classes.btn} onClick={()=>{localStorage.setItem('page','enduser');navigate('/registerUser')}}>End User</button>
          </div>
        </div>
        <div className={classes.right}>
          <img src={faceLogo} alt="apolloLogo" className={classes.faceLogo} />
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
